import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = { fetchPolicy: "no-cache" } as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type AllSparkIdsResponse = {
  __typename?: "AllSparkIdsResponse";
  ids?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  userId?: Maybe<Scalars["String"]>;
};

export type AudioBookInput = {
  author?: InputMaybe<Scalars["String"]>;
  chapter?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
};

export type Audiobook = {
  __typename?: "Audiobook";
  author?: Maybe<Scalars["String"]>;
  chapter?: Maybe<Scalars["String"]>;
  legacyId?: Maybe<Scalars["String"]>;
  metadata?: Maybe<Metadata>;
  playhead?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type Book = {
  __typename?: "Book";
  ISBN?: Maybe<Scalars["String"]>;
  author?: Maybe<Scalars["String"]>;
  chapter?: Maybe<Scalars["String"]>;
  legacyId?: Maybe<Scalars["String"]>;
  metadata?: Maybe<Metadata>;
  title?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type BookInput = {
  author?: InputMaybe<Scalars["String"]>;
  chapter?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
};

export type Cluster = {
  __typename?: "Cluster";
  createdAt?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  shared?: Maybe<Scalars["Boolean"]>;
  sparks?: Maybe<Array<Maybe<Spark>>>;
  updatedAt?: Maybe<Scalars["String"]>;
  user?: Maybe<User>;
};

export type ClusterInput = {
  description?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  name?: InputMaybe<Scalars["String"]>;
  shared?: InputMaybe<Scalars["Boolean"]>;
  user?: InputMaybe<UserInput>;
};

export type ClustersResponse = {
  __typename?: "ClustersResponse";
  clusters?: Maybe<Array<Maybe<Cluster>>>;
  hasMore?: Maybe<Scalars["Boolean"]>;
};

export type Connection = {
  __typename?: "Connection";
  clusters?: Maybe<Array<Maybe<Cluster>>>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  deletedAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  /** @deprecated use sparks */
  sparkIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  sparks?: Maybe<Array<Maybe<Spark>>>;
  upDateTimedAt?: Maybe<Scalars["DateTime"]>;
};

export type CreateSparkParams = {
  cluster?: InputMaybe<ClusterInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  media?: InputMaybe<MediaInput>;
  notes?: InputMaybe<Scalars["String"]>;
  originalNote?: InputMaybe<Scalars["String"]>;
  resource?: InputMaybe<ResourceInput>;
  url?: InputMaybe<Scalars["String"]>;
  user?: InputMaybe<UserInput>;
};

export type GetAllSparkIdsArgs = {
  userId?: InputMaybe<Scalars["ID"]>;
};

export type GetClusterArgs = {
  id: Scalars["ID"];
};

export type GetClusterParams = {
  id: Scalars["ID"];
};

export type GetClustersArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  shared?: InputMaybe<Scalars["Boolean"]>;
  userId?: InputMaybe<Scalars["ID"]>;
};

export type GetRelatedSparksArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sparkId?: InputMaybe<Scalars["ID"]>;
  userId?: InputMaybe<Scalars["ID"]>;
};

export type GetSparkArgs = {
  id: Scalars["ID"];
};

export type GetSparksArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  userId?: InputMaybe<Scalars["ID"]>;
};

export type GetSparksSharedWithUserParams = {
  clusterId?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["String"]>;
};

export type GetSparksToConnectToSparkArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  shared?: InputMaybe<Scalars["Boolean"]>;
  sparkId?: InputMaybe<Scalars["ID"]>;
  userId?: InputMaybe<Scalars["ID"]>;
};

export type Log = {
  __typename?: "Log";
  cluster?: Maybe<Cluster>;
  cosineSimilarity?: Maybe<Scalars["Float"]>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  legacyId?: Maybe<Scalars["String"]>;
  processedBy: User;
  processedFor: User;
  reason?: Maybe<Scalars["String"]>;
  sparks: Array<Spark>;
  type: LogType;
  updatedAt: Scalars["DateTime"];
  updatedUser?: Maybe<Scalars["Boolean"]>;
};

export enum LogType {
  AutoConnectionAdd = "AUTO_CONNECTION_ADD",
  ClusterAdd = "CLUSTER_ADD",
  ClusterCreate = "CLUSTER_CREATE",
  ClusterDelete = "CLUSTER_DELETE",
  ClusterEdit = "CLUSTER_EDIT",
  ClusterRemove = "CLUSTER_REMOVE",
  ConnectionAdd = "CONNECTION_ADD",
  ConnectionRemove = "CONNECTION_REMOVE",
}

export type Media = {
  __typename?: "Media";
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  legacyId?: Maybe<Scalars["String"]>;
  publicId?: Maybe<Scalars["String"]>;
  spark?: Maybe<Spark>;
  sparkId?: Maybe<Scalars["String"]>;
  type: MediaType;
  updatedAt: Scalars["DateTime"];
  url?: Maybe<Scalars["String"]>;
};

export type MediaInput = {
  publicId?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<MediaType>;
  url?: InputMaybe<Scalars["String"]>;
};

export enum MediaType {
  Image = "IMAGE",
  Video = "VIDEO",
}

export type Metadata = {
  __typename?: "Metadata";
  author?: Maybe<Scalars["String"]>;
  chapter?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  ogData?: Maybe<OpenGraphData>;
  platform?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  createSpark?: Maybe<Spark>;
};

export type MutationCreateSparkArgs = {
  params?: InputMaybe<CreateSparkParams>;
};

export type OpenGraphArticle = {
  __typename?: "OpenGraphArticle";
  author?: Maybe<Array<Maybe<OpenGraphProfile>>>;
  expirationTime?: Maybe<Scalars["DateTime"]>;
  modifiedTime?: Maybe<Scalars["DateTime"]>;
  publishedTime?: Maybe<Scalars["DateTime"]>;
  publisher?: Maybe<Scalars["String"]>;
  section?: Maybe<Scalars["String"]>;
  tags?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type OpenGraphAudio = {
  __typename?: "OpenGraphAudio";
  secureUrl?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type OpenGraphBook = {
  __typename?: "OpenGraphBook";
  author?: Maybe<Array<Maybe<OpenGraphProfile>>>;
  isbn?: Maybe<Scalars["String"]>;
  releaseDateTime?: Maybe<Scalars["DateTime"]>;
  tags?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type OpenGraphData = {
  __typename?: "OpenGraphData";
  article?: Maybe<OpenGraphArticle>;
  audio?: Maybe<OpenGraphAudio>;
  book?: Maybe<OpenGraphBook>;
  description?: Maybe<Scalars["String"]>;
  determiner?: Maybe<Scalars["String"]>;
  image?: Maybe<Array<Maybe<OpenGraphImage>>>;
  locale?: Maybe<Scalars["String"]>;
  localeAlternate?: Maybe<Scalars["String"]>;
  profile?: Maybe<OpenGraphProfile>;
  siteName?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
  video?: Maybe<OpenGraphVideo>;
};

export type OpenGraphImage = {
  __typename?: "OpenGraphImage";
  alt?: Maybe<Scalars["String"]>;
  height?: Maybe<Scalars["String"]>;
  secureUrl?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
  width?: Maybe<Scalars["String"]>;
};

export type OpenGraphProfile = {
  __typename?: "OpenGraphProfile";
  firstName?: Maybe<Scalars["String"]>;
  gender?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
};

export type OpenGraphVideo = {
  __typename?: "OpenGraphVideo";
  alt?: Maybe<Scalars["String"]>;
  height?: Maybe<Scalars["String"]>;
  secureUrl?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
  width?: Maybe<Scalars["String"]>;
};

export type Podcast = {
  __typename?: "Podcast";
  artworkUrl?: Maybe<Scalars["String"]>;
  author?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  feedUrl?: Maybe<Scalars["String"]>;
  itunesId?: Maybe<Scalars["String"]>;
  legacyId?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type PodcastEpisode = {
  __typename?: "PodcastEpisode";
  description?: Maybe<Scalars["String"]>;
  duration?: Maybe<Scalars["Float"]>;
  itunesId?: Maybe<Scalars["String"]>;
  lastPlayed?: Maybe<Scalars["String"]>;
  legacyId?: Maybe<Scalars["String"]>;
  metadata?: Maybe<Metadata>;
  name?: Maybe<Scalars["String"]>;
  number?: Maybe<Scalars["String"]>;
  playhead?: Maybe<Scalars["Float"]>;
  podcast?: Maybe<Podcast>;
  publishedDateTime?: Maybe<Scalars["DateTime"]>;
  url?: Maybe<Scalars["String"]>;
};

export type PodcastEpisodeInput = {
  url?: InputMaybe<Scalars["String"]>;
};

export type Query = {
  __typename?: "Query";
  getAllSparkIds?: Maybe<AllSparkIdsResponse>;
  getCluster?: Maybe<Cluster>;
  getClusterData: Array<Cluster>;
  getClusterIsShared?: Maybe<Cluster>;
  getClusters?: Maybe<ClustersResponse>;
  getRelatedSparks?: Maybe<RelatedSparksResponse>;
  getSpark?: Maybe<Spark>;
  getSparkIsShared?: Maybe<Spark>;
  getSparks?: Maybe<SparksResponse>;
  getSparksSharedWithUser?: Maybe<Array<Maybe<Spark>>>;
  getSparksToConnectToSpark: SparksToConnectToSparkResponse;
};

export type QueryGetAllSparkIdsArgs = {
  args?: InputMaybe<GetAllSparkIdsArgs>;
};

export type QueryGetClusterArgs = {
  args?: InputMaybe<GetClusterArgs>;
};

export type QueryGetClusterDataArgs = {
  params?: InputMaybe<GetClusterParams>;
};

export type QueryGetClusterIsSharedArgs = {
  args?: InputMaybe<GetClusterArgs>;
};

export type QueryGetClustersArgs = {
  args?: InputMaybe<GetClustersArgs>;
};

export type QueryGetRelatedSparksArgs = {
  args?: InputMaybe<GetRelatedSparksArgs>;
};

export type QueryGetSparkArgs = {
  args?: InputMaybe<GetSparkArgs>;
};

export type QueryGetSparkIsSharedArgs = {
  args?: InputMaybe<GetSparkArgs>;
};

export type QueryGetSparksArgs = {
  args?: InputMaybe<GetSparksArgs>;
};

export type QueryGetSparksSharedWithUserArgs = {
  params?: InputMaybe<GetSparksSharedWithUserParams>;
};

export type QueryGetSparksToConnectToSparkArgs = {
  args?: InputMaybe<GetSparksToConnectToSparkArgs>;
};

export type RelatedSparksResponse = {
  __typename?: "RelatedSparksResponse";
  hasMore?: Maybe<Scalars["Boolean"]>;
  sparks: Array<Maybe<Spark>>;
};

export type Resource = {
  __typename?: "Resource";
  audiobook?: Maybe<Audiobook>;
  book?: Maybe<Book>;
  category?: Maybe<ResourceCategory>;
  /** @deprecated use id */
  legacyId?: Maybe<Scalars["String"]>;
  metadata?: Maybe<Metadata>;
  podcastEpisode?: Maybe<PodcastEpisode>;
  social?: Maybe<Social>;
  video?: Maybe<Video>;
  website?: Maybe<Website>;
};

export enum ResourceCategory {
  Audiobook = "AUDIOBOOK",
  Book = "BOOK",
  PodcastEpisode = "PODCAST_EPISODE",
  Social = "SOCIAL",
  Video = "VIDEO",
  Website = "WEBSITE",
}

export type ResourceInput = {
  audiobook?: InputMaybe<AudioBookInput>;
  book?: InputMaybe<BookInput>;
  category: ResourceCategory;
  podcastEpisode?: InputMaybe<PodcastEpisodeInput>;
  social?: InputMaybe<SocialInput>;
  video?: InputMaybe<VideoInput>;
  website?: InputMaybe<WebsiteInput>;
};

export type Social = {
  __typename?: "Social";
  author?: Maybe<Scalars["String"]>;
  /** @deprecated use id */
  legacyId?: Maybe<Scalars["String"]>;
  metadata?: Maybe<Metadata>;
  platform?: Maybe<SocialPlatform>;
  url?: Maybe<Scalars["String"]>;
};

export type SocialInput = {
  url?: InputMaybe<Scalars["String"]>;
};

export enum SocialPlatform {
  Facebook = "FACEBOOK",
  Instagram = "INSTAGRAM",
  Linkedin = "LINKEDIN",
  Twitter = "TWITTER",
}

export type Spark = {
  __typename?: "Spark";
  /** @deprecated use clusters */
  clusterIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  clusters?: Maybe<Array<Maybe<Cluster>>>;
  /** @deprecated use connections */
  connectionIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  connections?: Maybe<Array<Maybe<Connection>>>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  deletedAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  /** @deprecated use id */
  legacyId?: Maybe<Scalars["String"]>;
  /** @deprecated use logs */
  logIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  logs?: Maybe<Array<Maybe<Log>>>;
  media?: Maybe<Media>;
  /** @deprecated media id */
  mediaId?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  notes?: Maybe<Scalars["String"]>;
  originalNote?: Maybe<Scalars["String"]>;
  resource?: Maybe<Resource>;
  shared?: Maybe<Scalars["Boolean"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  user?: Maybe<User>;
};

export type SparksResponse = {
  __typename?: "SparksResponse";
  hasMore?: Maybe<Scalars["Boolean"]>;
  sparks?: Maybe<Array<Maybe<Spark>>>;
};

export type SparksToConnectToSparkResponse = {
  __typename?: "SparksToConnectToSparkResponse";
  hasMore?: Maybe<Scalars["Boolean"]>;
  sparks: Array<Maybe<Spark>>;
};

export type User = {
  __typename?: "User";
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  lastName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type UserInput = {
  id: Scalars["ID"];
};

export type Video = {
  __typename?: "Video";
  author?: Maybe<Scalars["String"]>;
  /** @deprecated use id */
  legacyId?: Maybe<Scalars["String"]>;
  metadata?: Maybe<Metadata>;
  playhead?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type VideoInput = {
  url?: InputMaybe<Scalars["String"]>;
};

export type Website = {
  __typename?: "Website";
  /** @deprecated use id */
  legacyId?: Maybe<Scalars["String"]>;
  metadata?: Maybe<Metadata>;
  url?: Maybe<Scalars["String"]>;
};

export type WebsiteInput = {
  url?: InputMaybe<Scalars["String"]>;
};

export type GetClusterDataQueryVariables = Exact<{
  params?: InputMaybe<GetClusterParams>;
}>;

export type GetClusterDataQuery = {
  __typename?: "Query";
  getClusterData: Array<{
    __typename?: "Cluster";
    id: string;
    name?: string | null;
  }>;
};

export type GetClusterQueryVariables = Exact<{
  args?: InputMaybe<GetClusterArgs>;
}>;

export type GetClusterQuery = {
  __typename?: "Query";
  getCluster?: {
    __typename?: "Cluster";
    id: string;
    name?: string | null;
    description?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    shared?: boolean | null;
    sparks?: Array<{ __typename?: "Spark"; id: string } | null> | null;
    user?: {
      __typename?: "User";
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      name?: string | null;
    } | null;
  } | null;
};

export type GetClusterIsSharedQueryVariables = Exact<{
  args?: InputMaybe<GetClusterArgs>;
}>;

export type GetClusterIsSharedQuery = {
  __typename?: "Query";
  getClusterIsShared?: {
    __typename?: "Cluster";
    shared?: boolean | null;
  } | null;
};

export type GetClustersQueryVariables = Exact<{
  args?: InputMaybe<GetClustersArgs>;
}>;

export type GetClustersQuery = {
  __typename?: "Query";
  getClusters?: {
    __typename?: "ClustersResponse";
    clusters?: Array<{
      __typename?: "Cluster";
      id: string;
      name?: string | null;
      description?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      shared?: boolean | null;
      user?: {
        __typename?: "User";
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        name?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ClusterFragment = {
  __typename?: "Cluster";
  id: string;
  name?: string | null;
  description?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  shared?: boolean | null;
  user?: {
    __typename?: "User";
    id: string;
    firstName?: string | null;
    name?: string | null;
  } | null;
};

export type MetadataFragment = {
  __typename?: "Metadata";
  title?: string | null;
  description?: string | null;
  url?: string | null;
  author?: string | null;
  platform?: string | null;
  chapter?: string | null;
  ogData?: {
    __typename?: "OpenGraphData";
    title?: string | null;
    type?: string | null;
    url?: string | null;
    description?: string | null;
    determiner?: string | null;
    locale?: string | null;
    localeAlternate?: string | null;
    siteName?: string | null;
  } | null;
};

export type SparkFragment = {
  __typename?: "Spark";
  id: string;
  notes?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  shared?: boolean | null;
  clusters?: Array<{
    __typename?: "Cluster";
    id: string;
    name?: string | null;
    shared?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null> | null;
  connections?: Array<{
    __typename?: "Connection";
    id: string;
    createdAt?: any | null;
    sparks?: Array<{
      __typename?: "Spark";
      id: string;
      notes?: string | null;
      createdAt?: any | null;
      shared?: boolean | null;
      user?: { __typename?: "User"; id: string } | null;
    } | null> | null;
  } | null> | null;
  media?: { __typename?: "Media"; type: MediaType; url?: string | null } | null;
  resource?: {
    __typename?: "Resource";
    category?: ResourceCategory | null;
    metadata?: {
      __typename?: "Metadata";
      title?: string | null;
      description?: string | null;
      url?: string | null;
      author?: string | null;
      platform?: string | null;
      chapter?: string | null;
      ogData?: {
        __typename?: "OpenGraphData";
        title?: string | null;
        type?: string | null;
        url?: string | null;
        description?: string | null;
        determiner?: string | null;
        locale?: string | null;
        localeAlternate?: string | null;
        siteName?: string | null;
      } | null;
    } | null;
    website?: {
      __typename?: "Website";
      url?: string | null;
      metadata?: {
        __typename?: "Metadata";
        title?: string | null;
        description?: string | null;
        url?: string | null;
        author?: string | null;
        platform?: string | null;
        chapter?: string | null;
        ogData?: {
          __typename?: "OpenGraphData";
          title?: string | null;
          type?: string | null;
          description?: string | null;
          url?: string | null;
          determiner?: string | null;
          locale?: string | null;
          localeAlternate?: string | null;
          siteName?: string | null;
        } | null;
      } | null;
    } | null;
    social?: {
      __typename?: "Social";
      author?: string | null;
      url?: string | null;
      platform?: SocialPlatform | null;
      metadata?: {
        __typename?: "Metadata";
        title?: string | null;
        description?: string | null;
        url?: string | null;
        author?: string | null;
        platform?: string | null;
        chapter?: string | null;
        ogData?: {
          __typename?: "OpenGraphData";
          title?: string | null;
          type?: string | null;
          url?: string | null;
          description?: string | null;
          determiner?: string | null;
          locale?: string | null;
          localeAlternate?: string | null;
          siteName?: string | null;
        } | null;
      } | null;
    } | null;
    video?: {
      __typename?: "Video";
      url?: string | null;
      metadata?: {
        __typename?: "Metadata";
        title?: string | null;
        description?: string | null;
        url?: string | null;
        author?: string | null;
        platform?: string | null;
        chapter?: string | null;
        ogData?: {
          __typename?: "OpenGraphData";
          title?: string | null;
          type?: string | null;
          url?: string | null;
          description?: string | null;
          determiner?: string | null;
          locale?: string | null;
          localeAlternate?: string | null;
          siteName?: string | null;
        } | null;
      } | null;
    } | null;
    book?: {
      __typename?: "Book";
      title?: string | null;
      author?: string | null;
      chapter?: string | null;
      metadata?: {
        __typename?: "Metadata";
        title?: string | null;
        description?: string | null;
        url?: string | null;
        author?: string | null;
        platform?: string | null;
        chapter?: string | null;
        ogData?: {
          __typename?: "OpenGraphData";
          title?: string | null;
          type?: string | null;
          url?: string | null;
          description?: string | null;
          determiner?: string | null;
          locale?: string | null;
          localeAlternate?: string | null;
          siteName?: string | null;
        } | null;
      } | null;
    } | null;
    audiobook?: {
      __typename?: "Audiobook";
      chapter?: string | null;
      title?: string | null;
      author?: string | null;
      url?: string | null;
      metadata?: {
        __typename?: "Metadata";
        title?: string | null;
        description?: string | null;
        url?: string | null;
        author?: string | null;
        platform?: string | null;
        chapter?: string | null;
        ogData?: {
          __typename?: "OpenGraphData";
          title?: string | null;
          type?: string | null;
          url?: string | null;
          description?: string | null;
          determiner?: string | null;
          locale?: string | null;
          localeAlternate?: string | null;
          siteName?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  user?: {
    __typename?: "User";
    id: string;
    firstName?: string | null;
    name?: string | null;
  } | null;
};

export type GetSparkQueryVariables = Exact<{
  args?: InputMaybe<GetSparkArgs>;
}>;

export type GetSparkQuery = {
  __typename?: "Query";
  getSpark?: {
    __typename?: "Spark";
    id: string;
    notes?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    shared?: boolean | null;
    clusters?: Array<{
      __typename?: "Cluster";
      id: string;
      name?: string | null;
      shared?: boolean | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      user?: {
        __typename?: "User";
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        name?: string | null;
      } | null;
    } | null> | null;
    connections?: Array<{
      __typename?: "Connection";
      id: string;
      createdAt?: any | null;
      sparks?: Array<{
        __typename?: "Spark";
        id: string;
        notes?: string | null;
        createdAt?: any | null;
        shared?: boolean | null;
        updatedAt?: any | null;
        user?: {
          __typename?: "User";
          id: string;
          firstName?: string | null;
          name?: string | null;
        } | null;
        clusters?: Array<{
          __typename?: "Cluster";
          id: string;
          name?: string | null;
          shared?: boolean | null;
          createdAt?: string | null;
          updatedAt?: string | null;
        } | null> | null;
        connections?: Array<{
          __typename?: "Connection";
          id: string;
          createdAt?: any | null;
          sparks?: Array<{
            __typename?: "Spark";
            id: string;
            notes?: string | null;
            createdAt?: any | null;
            shared?: boolean | null;
            user?: { __typename?: "User"; id: string } | null;
          } | null> | null;
        } | null> | null;
        media?: {
          __typename?: "Media";
          type: MediaType;
          url?: string | null;
        } | null;
        resource?: {
          __typename?: "Resource";
          category?: ResourceCategory | null;
          metadata?: {
            __typename?: "Metadata";
            title?: string | null;
            description?: string | null;
            url?: string | null;
            author?: string | null;
            platform?: string | null;
            chapter?: string | null;
            ogData?: {
              __typename?: "OpenGraphData";
              title?: string | null;
              type?: string | null;
              url?: string | null;
              description?: string | null;
              determiner?: string | null;
              locale?: string | null;
              localeAlternate?: string | null;
              siteName?: string | null;
            } | null;
          } | null;
          website?: {
            __typename?: "Website";
            url?: string | null;
            metadata?: {
              __typename?: "Metadata";
              title?: string | null;
              description?: string | null;
              url?: string | null;
              author?: string | null;
              platform?: string | null;
              chapter?: string | null;
              ogData?: {
                __typename?: "OpenGraphData";
                title?: string | null;
                type?: string | null;
                description?: string | null;
                url?: string | null;
                determiner?: string | null;
                locale?: string | null;
                localeAlternate?: string | null;
                siteName?: string | null;
              } | null;
            } | null;
          } | null;
          social?: {
            __typename?: "Social";
            author?: string | null;
            url?: string | null;
            platform?: SocialPlatform | null;
            metadata?: {
              __typename?: "Metadata";
              title?: string | null;
              description?: string | null;
              url?: string | null;
              author?: string | null;
              platform?: string | null;
              chapter?: string | null;
              ogData?: {
                __typename?: "OpenGraphData";
                title?: string | null;
                type?: string | null;
                url?: string | null;
                description?: string | null;
                determiner?: string | null;
                locale?: string | null;
                localeAlternate?: string | null;
                siteName?: string | null;
              } | null;
            } | null;
          } | null;
          video?: {
            __typename?: "Video";
            url?: string | null;
            metadata?: {
              __typename?: "Metadata";
              title?: string | null;
              description?: string | null;
              url?: string | null;
              author?: string | null;
              platform?: string | null;
              chapter?: string | null;
              ogData?: {
                __typename?: "OpenGraphData";
                title?: string | null;
                type?: string | null;
                url?: string | null;
                description?: string | null;
                determiner?: string | null;
                locale?: string | null;
                localeAlternate?: string | null;
                siteName?: string | null;
              } | null;
            } | null;
          } | null;
          book?: {
            __typename?: "Book";
            title?: string | null;
            author?: string | null;
            chapter?: string | null;
            metadata?: {
              __typename?: "Metadata";
              title?: string | null;
              description?: string | null;
              url?: string | null;
              author?: string | null;
              platform?: string | null;
              chapter?: string | null;
              ogData?: {
                __typename?: "OpenGraphData";
                title?: string | null;
                type?: string | null;
                url?: string | null;
                description?: string | null;
                determiner?: string | null;
                locale?: string | null;
                localeAlternate?: string | null;
                siteName?: string | null;
              } | null;
            } | null;
          } | null;
          audiobook?: {
            __typename?: "Audiobook";
            chapter?: string | null;
            title?: string | null;
            author?: string | null;
            url?: string | null;
            metadata?: {
              __typename?: "Metadata";
              title?: string | null;
              description?: string | null;
              url?: string | null;
              author?: string | null;
              platform?: string | null;
              chapter?: string | null;
              ogData?: {
                __typename?: "OpenGraphData";
                title?: string | null;
                type?: string | null;
                url?: string | null;
                description?: string | null;
                determiner?: string | null;
                locale?: string | null;
                localeAlternate?: string | null;
                siteName?: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
    } | null> | null;
    media?: {
      __typename?: "Media";
      type: MediaType;
      url?: string | null;
    } | null;
    resource?: {
      __typename?: "Resource";
      category?: ResourceCategory | null;
      metadata?: {
        __typename?: "Metadata";
        title?: string | null;
        description?: string | null;
        url?: string | null;
        author?: string | null;
        platform?: string | null;
        chapter?: string | null;
        ogData?: {
          __typename?: "OpenGraphData";
          title?: string | null;
          type?: string | null;
          url?: string | null;
          description?: string | null;
          determiner?: string | null;
          locale?: string | null;
          localeAlternate?: string | null;
          siteName?: string | null;
        } | null;
      } | null;
      website?: {
        __typename?: "Website";
        url?: string | null;
        metadata?: {
          __typename?: "Metadata";
          title?: string | null;
          description?: string | null;
          url?: string | null;
          author?: string | null;
          platform?: string | null;
          chapter?: string | null;
          ogData?: {
            __typename?: "OpenGraphData";
            title?: string | null;
            type?: string | null;
            description?: string | null;
            url?: string | null;
            determiner?: string | null;
            locale?: string | null;
            localeAlternate?: string | null;
            siteName?: string | null;
          } | null;
        } | null;
      } | null;
      social?: {
        __typename?: "Social";
        author?: string | null;
        url?: string | null;
        platform?: SocialPlatform | null;
        metadata?: {
          __typename?: "Metadata";
          title?: string | null;
          description?: string | null;
          url?: string | null;
          author?: string | null;
          platform?: string | null;
          chapter?: string | null;
          ogData?: {
            __typename?: "OpenGraphData";
            title?: string | null;
            type?: string | null;
            url?: string | null;
            description?: string | null;
            determiner?: string | null;
            locale?: string | null;
            localeAlternate?: string | null;
            siteName?: string | null;
          } | null;
        } | null;
      } | null;
      video?: {
        __typename?: "Video";
        url?: string | null;
        metadata?: {
          __typename?: "Metadata";
          title?: string | null;
          description?: string | null;
          url?: string | null;
          author?: string | null;
          platform?: string | null;
          chapter?: string | null;
          ogData?: {
            __typename?: "OpenGraphData";
            title?: string | null;
            type?: string | null;
            url?: string | null;
            description?: string | null;
            determiner?: string | null;
            locale?: string | null;
            localeAlternate?: string | null;
            siteName?: string | null;
          } | null;
        } | null;
      } | null;
      book?: {
        __typename?: "Book";
        title?: string | null;
        author?: string | null;
        chapter?: string | null;
        metadata?: {
          __typename?: "Metadata";
          title?: string | null;
          description?: string | null;
          url?: string | null;
          author?: string | null;
          platform?: string | null;
          chapter?: string | null;
          ogData?: {
            __typename?: "OpenGraphData";
            title?: string | null;
            type?: string | null;
            url?: string | null;
            description?: string | null;
            determiner?: string | null;
            locale?: string | null;
            localeAlternate?: string | null;
            siteName?: string | null;
          } | null;
        } | null;
      } | null;
      audiobook?: {
        __typename?: "Audiobook";
        chapter?: string | null;
        title?: string | null;
        author?: string | null;
        url?: string | null;
        metadata?: {
          __typename?: "Metadata";
          title?: string | null;
          description?: string | null;
          url?: string | null;
          author?: string | null;
          platform?: string | null;
          chapter?: string | null;
          ogData?: {
            __typename?: "OpenGraphData";
            title?: string | null;
            type?: string | null;
            url?: string | null;
            description?: string | null;
            determiner?: string | null;
            locale?: string | null;
            localeAlternate?: string | null;
            siteName?: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    user?: {
      __typename?: "User";
      id: string;
      firstName?: string | null;
      name?: string | null;
    } | null;
  } | null;
};

export type GetSparksQueryVariables = Exact<{
  args?: InputMaybe<GetSparksArgs>;
}>;

export type GetSparksQuery = {
  __typename?: "Query";
  getSparks?: {
    __typename?: "SparksResponse";
    hasMore?: boolean | null;
    sparks?: Array<{
      __typename?: "Spark";
      id: string;
      notes?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      shared?: boolean | null;
      clusters?: Array<{
        __typename?: "Cluster";
        id: string;
        name?: string | null;
        shared?: boolean | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      connections?: Array<{
        __typename?: "Connection";
        id: string;
        createdAt?: any | null;
        sparks?: Array<{
          __typename?: "Spark";
          id: string;
          notes?: string | null;
          createdAt?: any | null;
          shared?: boolean | null;
          updatedAt?: any | null;
          user?: {
            __typename?: "User";
            id: string;
            firstName?: string | null;
            name?: string | null;
          } | null;
          clusters?: Array<{
            __typename?: "Cluster";
            id: string;
            name?: string | null;
            shared?: boolean | null;
            createdAt?: string | null;
            updatedAt?: string | null;
          } | null> | null;
          connections?: Array<{
            __typename?: "Connection";
            id: string;
            createdAt?: any | null;
            sparks?: Array<{
              __typename?: "Spark";
              id: string;
              notes?: string | null;
              createdAt?: any | null;
              shared?: boolean | null;
              user?: { __typename?: "User"; id: string } | null;
            } | null> | null;
          } | null> | null;
          media?: {
            __typename?: "Media";
            type: MediaType;
            url?: string | null;
          } | null;
          resource?: {
            __typename?: "Resource";
            category?: ResourceCategory | null;
            metadata?: {
              __typename?: "Metadata";
              title?: string | null;
              description?: string | null;
              url?: string | null;
              author?: string | null;
              platform?: string | null;
              chapter?: string | null;
              ogData?: {
                __typename?: "OpenGraphData";
                title?: string | null;
                type?: string | null;
                url?: string | null;
                description?: string | null;
                determiner?: string | null;
                locale?: string | null;
                localeAlternate?: string | null;
                siteName?: string | null;
              } | null;
            } | null;
            website?: {
              __typename?: "Website";
              url?: string | null;
              metadata?: {
                __typename?: "Metadata";
                title?: string | null;
                description?: string | null;
                url?: string | null;
                author?: string | null;
                platform?: string | null;
                chapter?: string | null;
                ogData?: {
                  __typename?: "OpenGraphData";
                  title?: string | null;
                  type?: string | null;
                  description?: string | null;
                  url?: string | null;
                  determiner?: string | null;
                  locale?: string | null;
                  localeAlternate?: string | null;
                  siteName?: string | null;
                } | null;
              } | null;
            } | null;
            social?: {
              __typename?: "Social";
              author?: string | null;
              url?: string | null;
              platform?: SocialPlatform | null;
              metadata?: {
                __typename?: "Metadata";
                title?: string | null;
                description?: string | null;
                url?: string | null;
                author?: string | null;
                platform?: string | null;
                chapter?: string | null;
                ogData?: {
                  __typename?: "OpenGraphData";
                  title?: string | null;
                  type?: string | null;
                  url?: string | null;
                  description?: string | null;
                  determiner?: string | null;
                  locale?: string | null;
                  localeAlternate?: string | null;
                  siteName?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: "Video";
              url?: string | null;
              metadata?: {
                __typename?: "Metadata";
                title?: string | null;
                description?: string | null;
                url?: string | null;
                author?: string | null;
                platform?: string | null;
                chapter?: string | null;
                ogData?: {
                  __typename?: "OpenGraphData";
                  title?: string | null;
                  type?: string | null;
                  url?: string | null;
                  description?: string | null;
                  determiner?: string | null;
                  locale?: string | null;
                  localeAlternate?: string | null;
                  siteName?: string | null;
                } | null;
              } | null;
            } | null;
            book?: {
              __typename?: "Book";
              title?: string | null;
              author?: string | null;
              chapter?: string | null;
              metadata?: {
                __typename?: "Metadata";
                title?: string | null;
                description?: string | null;
                url?: string | null;
                author?: string | null;
                platform?: string | null;
                chapter?: string | null;
                ogData?: {
                  __typename?: "OpenGraphData";
                  title?: string | null;
                  type?: string | null;
                  url?: string | null;
                  description?: string | null;
                  determiner?: string | null;
                  locale?: string | null;
                  localeAlternate?: string | null;
                  siteName?: string | null;
                } | null;
              } | null;
            } | null;
            audiobook?: {
              __typename?: "Audiobook";
              chapter?: string | null;
              title?: string | null;
              author?: string | null;
              url?: string | null;
              metadata?: {
                __typename?: "Metadata";
                title?: string | null;
                description?: string | null;
                url?: string | null;
                author?: string | null;
                platform?: string | null;
                chapter?: string | null;
                ogData?: {
                  __typename?: "OpenGraphData";
                  title?: string | null;
                  type?: string | null;
                  url?: string | null;
                  description?: string | null;
                  determiner?: string | null;
                  locale?: string | null;
                  localeAlternate?: string | null;
                  siteName?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
      } | null> | null;
      user?: {
        __typename?: "User";
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        name?: string | null;
      } | null;
      media?: {
        __typename?: "Media";
        type: MediaType;
        url?: string | null;
      } | null;
      resource?: {
        __typename?: "Resource";
        category?: ResourceCategory | null;
        metadata?: {
          __typename?: "Metadata";
          title?: string | null;
          description?: string | null;
          url?: string | null;
          author?: string | null;
          platform?: string | null;
          chapter?: string | null;
          ogData?: {
            __typename?: "OpenGraphData";
            title?: string | null;
            type?: string | null;
            url?: string | null;
            description?: string | null;
            determiner?: string | null;
            locale?: string | null;
            localeAlternate?: string | null;
            siteName?: string | null;
          } | null;
        } | null;
        website?: {
          __typename?: "Website";
          url?: string | null;
          metadata?: {
            __typename?: "Metadata";
            title?: string | null;
            description?: string | null;
            url?: string | null;
            author?: string | null;
            platform?: string | null;
            chapter?: string | null;
            ogData?: {
              __typename?: "OpenGraphData";
              title?: string | null;
              type?: string | null;
              description?: string | null;
              url?: string | null;
              determiner?: string | null;
              locale?: string | null;
              localeAlternate?: string | null;
              siteName?: string | null;
            } | null;
          } | null;
        } | null;
        social?: {
          __typename?: "Social";
          author?: string | null;
          url?: string | null;
          platform?: SocialPlatform | null;
          metadata?: {
            __typename?: "Metadata";
            title?: string | null;
            description?: string | null;
            url?: string | null;
            author?: string | null;
            platform?: string | null;
            chapter?: string | null;
            ogData?: {
              __typename?: "OpenGraphData";
              title?: string | null;
              type?: string | null;
              url?: string | null;
              description?: string | null;
              determiner?: string | null;
              locale?: string | null;
              localeAlternate?: string | null;
              siteName?: string | null;
            } | null;
          } | null;
        } | null;
        video?: {
          __typename?: "Video";
          url?: string | null;
          metadata?: {
            __typename?: "Metadata";
            title?: string | null;
            description?: string | null;
            url?: string | null;
            author?: string | null;
            platform?: string | null;
            chapter?: string | null;
            ogData?: {
              __typename?: "OpenGraphData";
              title?: string | null;
              type?: string | null;
              url?: string | null;
              description?: string | null;
              determiner?: string | null;
              locale?: string | null;
              localeAlternate?: string | null;
              siteName?: string | null;
            } | null;
          } | null;
        } | null;
        book?: {
          __typename?: "Book";
          title?: string | null;
          author?: string | null;
          chapter?: string | null;
          metadata?: {
            __typename?: "Metadata";
            title?: string | null;
            description?: string | null;
            url?: string | null;
            author?: string | null;
            platform?: string | null;
            chapter?: string | null;
            ogData?: {
              __typename?: "OpenGraphData";
              title?: string | null;
              type?: string | null;
              url?: string | null;
              description?: string | null;
              determiner?: string | null;
              locale?: string | null;
              localeAlternate?: string | null;
              siteName?: string | null;
            } | null;
          } | null;
        } | null;
        audiobook?: {
          __typename?: "Audiobook";
          chapter?: string | null;
          title?: string | null;
          author?: string | null;
          url?: string | null;
          metadata?: {
            __typename?: "Metadata";
            title?: string | null;
            description?: string | null;
            url?: string | null;
            author?: string | null;
            platform?: string | null;
            chapter?: string | null;
            ogData?: {
              __typename?: "OpenGraphData";
              title?: string | null;
              type?: string | null;
              url?: string | null;
              description?: string | null;
              determiner?: string | null;
              locale?: string | null;
              localeAlternate?: string | null;
              siteName?: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetSparkIsSharedQueryVariables = Exact<{
  args?: InputMaybe<GetSparkArgs>;
}>;

export type GetSparkIsSharedQuery = {
  __typename?: "Query";
  getSparkIsShared?: { __typename?: "Spark"; shared?: boolean | null } | null;
};

export type GetSparksSharedWithUserQueryVariables = Exact<{
  params?: InputMaybe<GetSparksSharedWithUserParams>;
}>;

export type GetSparksSharedWithUserQuery = {
  __typename?: "Query";
  getSparksSharedWithUser?: Array<{
    __typename?: "Spark";
    id: string;
    notes?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    shared?: boolean | null;
    clusters?: Array<{
      __typename?: "Cluster";
      id: string;
      name?: string | null;
      shared?: boolean | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null> | null;
    connections?: Array<{
      __typename?: "Connection";
      id: string;
      createdAt?: any | null;
      sparks?: Array<{
        __typename?: "Spark";
        id: string;
        notes?: string | null;
        createdAt?: any | null;
        shared?: boolean | null;
        user?: { __typename?: "User"; id: string } | null;
      } | null> | null;
    } | null> | null;
    media?: {
      __typename?: "Media";
      type: MediaType;
      url?: string | null;
    } | null;
    resource?: {
      __typename?: "Resource";
      category?: ResourceCategory | null;
      metadata?: {
        __typename?: "Metadata";
        title?: string | null;
        description?: string | null;
        url?: string | null;
        author?: string | null;
        platform?: string | null;
        chapter?: string | null;
        ogData?: {
          __typename?: "OpenGraphData";
          title?: string | null;
          type?: string | null;
          url?: string | null;
          description?: string | null;
          determiner?: string | null;
          locale?: string | null;
          localeAlternate?: string | null;
          siteName?: string | null;
        } | null;
      } | null;
      website?: {
        __typename?: "Website";
        url?: string | null;
        metadata?: {
          __typename?: "Metadata";
          title?: string | null;
          description?: string | null;
          url?: string | null;
          author?: string | null;
          platform?: string | null;
          chapter?: string | null;
          ogData?: {
            __typename?: "OpenGraphData";
            title?: string | null;
            type?: string | null;
            description?: string | null;
            url?: string | null;
            determiner?: string | null;
            locale?: string | null;
            localeAlternate?: string | null;
            siteName?: string | null;
          } | null;
        } | null;
      } | null;
      social?: {
        __typename?: "Social";
        author?: string | null;
        url?: string | null;
        platform?: SocialPlatform | null;
        metadata?: {
          __typename?: "Metadata";
          title?: string | null;
          description?: string | null;
          url?: string | null;
          author?: string | null;
          platform?: string | null;
          chapter?: string | null;
          ogData?: {
            __typename?: "OpenGraphData";
            title?: string | null;
            type?: string | null;
            url?: string | null;
            description?: string | null;
            determiner?: string | null;
            locale?: string | null;
            localeAlternate?: string | null;
            siteName?: string | null;
          } | null;
        } | null;
      } | null;
      video?: {
        __typename?: "Video";
        url?: string | null;
        metadata?: {
          __typename?: "Metadata";
          title?: string | null;
          description?: string | null;
          url?: string | null;
          author?: string | null;
          platform?: string | null;
          chapter?: string | null;
          ogData?: {
            __typename?: "OpenGraphData";
            title?: string | null;
            type?: string | null;
            url?: string | null;
            description?: string | null;
            determiner?: string | null;
            locale?: string | null;
            localeAlternate?: string | null;
            siteName?: string | null;
          } | null;
        } | null;
      } | null;
      book?: {
        __typename?: "Book";
        title?: string | null;
        author?: string | null;
        chapter?: string | null;
        metadata?: {
          __typename?: "Metadata";
          title?: string | null;
          description?: string | null;
          url?: string | null;
          author?: string | null;
          platform?: string | null;
          chapter?: string | null;
          ogData?: {
            __typename?: "OpenGraphData";
            title?: string | null;
            type?: string | null;
            url?: string | null;
            description?: string | null;
            determiner?: string | null;
            locale?: string | null;
            localeAlternate?: string | null;
            siteName?: string | null;
          } | null;
        } | null;
      } | null;
      audiobook?: {
        __typename?: "Audiobook";
        chapter?: string | null;
        title?: string | null;
        author?: string | null;
        url?: string | null;
        metadata?: {
          __typename?: "Metadata";
          title?: string | null;
          description?: string | null;
          url?: string | null;
          author?: string | null;
          platform?: string | null;
          chapter?: string | null;
          ogData?: {
            __typename?: "OpenGraphData";
            title?: string | null;
            type?: string | null;
            url?: string | null;
            description?: string | null;
            determiner?: string | null;
            locale?: string | null;
            localeAlternate?: string | null;
            siteName?: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    user?: {
      __typename?: "User";
      id: string;
      firstName?: string | null;
      name?: string | null;
    } | null;
  } | null> | null;
};

export type GetRelatedSparksQueryVariables = Exact<{
  args?: InputMaybe<GetRelatedSparksArgs>;
}>;

export type GetRelatedSparksQuery = {
  __typename?: "Query";
  getRelatedSparks?: {
    __typename?: "RelatedSparksResponse";
    sparks: Array<{
      __typename?: "Spark";
      id: string;
      notes?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      shared?: boolean | null;
      clusters?: Array<{
        __typename?: "Cluster";
        id: string;
        name?: string | null;
        shared?: boolean | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      connections?: Array<{
        __typename?: "Connection";
        id: string;
        createdAt?: any | null;
        sparks?: Array<{
          __typename?: "Spark";
          id: string;
          notes?: string | null;
          createdAt?: any | null;
          shared?: boolean | null;
          user?: { __typename?: "User"; id: string } | null;
        } | null> | null;
      } | null> | null;
      media?: {
        __typename?: "Media";
        type: MediaType;
        url?: string | null;
      } | null;
      resource?: {
        __typename?: "Resource";
        category?: ResourceCategory | null;
        metadata?: {
          __typename?: "Metadata";
          title?: string | null;
          description?: string | null;
          url?: string | null;
          author?: string | null;
          platform?: string | null;
          chapter?: string | null;
          ogData?: {
            __typename?: "OpenGraphData";
            title?: string | null;
            type?: string | null;
            url?: string | null;
            description?: string | null;
            determiner?: string | null;
            locale?: string | null;
            localeAlternate?: string | null;
            siteName?: string | null;
          } | null;
        } | null;
        website?: {
          __typename?: "Website";
          url?: string | null;
          metadata?: {
            __typename?: "Metadata";
            title?: string | null;
            description?: string | null;
            url?: string | null;
            author?: string | null;
            platform?: string | null;
            chapter?: string | null;
            ogData?: {
              __typename?: "OpenGraphData";
              title?: string | null;
              type?: string | null;
              description?: string | null;
              url?: string | null;
              determiner?: string | null;
              locale?: string | null;
              localeAlternate?: string | null;
              siteName?: string | null;
            } | null;
          } | null;
        } | null;
        social?: {
          __typename?: "Social";
          author?: string | null;
          url?: string | null;
          platform?: SocialPlatform | null;
          metadata?: {
            __typename?: "Metadata";
            title?: string | null;
            description?: string | null;
            url?: string | null;
            author?: string | null;
            platform?: string | null;
            chapter?: string | null;
            ogData?: {
              __typename?: "OpenGraphData";
              title?: string | null;
              type?: string | null;
              url?: string | null;
              description?: string | null;
              determiner?: string | null;
              locale?: string | null;
              localeAlternate?: string | null;
              siteName?: string | null;
            } | null;
          } | null;
        } | null;
        video?: {
          __typename?: "Video";
          url?: string | null;
          metadata?: {
            __typename?: "Metadata";
            title?: string | null;
            description?: string | null;
            url?: string | null;
            author?: string | null;
            platform?: string | null;
            chapter?: string | null;
            ogData?: {
              __typename?: "OpenGraphData";
              title?: string | null;
              type?: string | null;
              url?: string | null;
              description?: string | null;
              determiner?: string | null;
              locale?: string | null;
              localeAlternate?: string | null;
              siteName?: string | null;
            } | null;
          } | null;
        } | null;
        book?: {
          __typename?: "Book";
          title?: string | null;
          author?: string | null;
          chapter?: string | null;
          metadata?: {
            __typename?: "Metadata";
            title?: string | null;
            description?: string | null;
            url?: string | null;
            author?: string | null;
            platform?: string | null;
            chapter?: string | null;
            ogData?: {
              __typename?: "OpenGraphData";
              title?: string | null;
              type?: string | null;
              url?: string | null;
              description?: string | null;
              determiner?: string | null;
              locale?: string | null;
              localeAlternate?: string | null;
              siteName?: string | null;
            } | null;
          } | null;
        } | null;
        audiobook?: {
          __typename?: "Audiobook";
          chapter?: string | null;
          title?: string | null;
          author?: string | null;
          url?: string | null;
          metadata?: {
            __typename?: "Metadata";
            title?: string | null;
            description?: string | null;
            url?: string | null;
            author?: string | null;
            platform?: string | null;
            chapter?: string | null;
            ogData?: {
              __typename?: "OpenGraphData";
              title?: string | null;
              type?: string | null;
              url?: string | null;
              description?: string | null;
              determiner?: string | null;
              locale?: string | null;
              localeAlternate?: string | null;
              siteName?: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      user?: {
        __typename?: "User";
        id: string;
        firstName?: string | null;
        name?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type GetSparksToConnectToSparkQueryVariables = Exact<{
  args?: InputMaybe<GetSparksToConnectToSparkArgs>;
}>;

export type GetSparksToConnectToSparkQuery = {
  __typename?: "Query";
  getSparksToConnectToSpark: {
    __typename?: "SparksToConnectToSparkResponse";
    sparks: Array<{
      __typename?: "Spark";
      id: string;
      notes?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      shared?: boolean | null;
      clusters?: Array<{
        __typename?: "Cluster";
        id: string;
        name?: string | null;
        shared?: boolean | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null> | null;
      connections?: Array<{
        __typename?: "Connection";
        id: string;
        createdAt?: any | null;
        sparks?: Array<{
          __typename?: "Spark";
          id: string;
          notes?: string | null;
          createdAt?: any | null;
          shared?: boolean | null;
          user?: { __typename?: "User"; id: string } | null;
        } | null> | null;
      } | null> | null;
      media?: {
        __typename?: "Media";
        type: MediaType;
        url?: string | null;
      } | null;
      resource?: {
        __typename?: "Resource";
        category?: ResourceCategory | null;
        metadata?: {
          __typename?: "Metadata";
          title?: string | null;
          description?: string | null;
          url?: string | null;
          author?: string | null;
          platform?: string | null;
          chapter?: string | null;
          ogData?: {
            __typename?: "OpenGraphData";
            title?: string | null;
            type?: string | null;
            url?: string | null;
            description?: string | null;
            determiner?: string | null;
            locale?: string | null;
            localeAlternate?: string | null;
            siteName?: string | null;
          } | null;
        } | null;
        website?: {
          __typename?: "Website";
          url?: string | null;
          metadata?: {
            __typename?: "Metadata";
            title?: string | null;
            description?: string | null;
            url?: string | null;
            author?: string | null;
            platform?: string | null;
            chapter?: string | null;
            ogData?: {
              __typename?: "OpenGraphData";
              title?: string | null;
              type?: string | null;
              description?: string | null;
              url?: string | null;
              determiner?: string | null;
              locale?: string | null;
              localeAlternate?: string | null;
              siteName?: string | null;
            } | null;
          } | null;
        } | null;
        social?: {
          __typename?: "Social";
          author?: string | null;
          url?: string | null;
          platform?: SocialPlatform | null;
          metadata?: {
            __typename?: "Metadata";
            title?: string | null;
            description?: string | null;
            url?: string | null;
            author?: string | null;
            platform?: string | null;
            chapter?: string | null;
            ogData?: {
              __typename?: "OpenGraphData";
              title?: string | null;
              type?: string | null;
              url?: string | null;
              description?: string | null;
              determiner?: string | null;
              locale?: string | null;
              localeAlternate?: string | null;
              siteName?: string | null;
            } | null;
          } | null;
        } | null;
        video?: {
          __typename?: "Video";
          url?: string | null;
          metadata?: {
            __typename?: "Metadata";
            title?: string | null;
            description?: string | null;
            url?: string | null;
            author?: string | null;
            platform?: string | null;
            chapter?: string | null;
            ogData?: {
              __typename?: "OpenGraphData";
              title?: string | null;
              type?: string | null;
              url?: string | null;
              description?: string | null;
              determiner?: string | null;
              locale?: string | null;
              localeAlternate?: string | null;
              siteName?: string | null;
            } | null;
          } | null;
        } | null;
        book?: {
          __typename?: "Book";
          title?: string | null;
          author?: string | null;
          chapter?: string | null;
          metadata?: {
            __typename?: "Metadata";
            title?: string | null;
            description?: string | null;
            url?: string | null;
            author?: string | null;
            platform?: string | null;
            chapter?: string | null;
            ogData?: {
              __typename?: "OpenGraphData";
              title?: string | null;
              type?: string | null;
              url?: string | null;
              description?: string | null;
              determiner?: string | null;
              locale?: string | null;
              localeAlternate?: string | null;
              siteName?: string | null;
            } | null;
          } | null;
        } | null;
        audiobook?: {
          __typename?: "Audiobook";
          chapter?: string | null;
          title?: string | null;
          author?: string | null;
          url?: string | null;
          metadata?: {
            __typename?: "Metadata";
            title?: string | null;
            description?: string | null;
            url?: string | null;
            author?: string | null;
            platform?: string | null;
            chapter?: string | null;
            ogData?: {
              __typename?: "OpenGraphData";
              title?: string | null;
              type?: string | null;
              url?: string | null;
              description?: string | null;
              determiner?: string | null;
              locale?: string | null;
              localeAlternate?: string | null;
              siteName?: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      user?: {
        __typename?: "User";
        id: string;
        firstName?: string | null;
        name?: string | null;
      } | null;
    } | null>;
  };
};

export type GetAllSparkIdsQueryVariables = Exact<{
  args?: InputMaybe<GetAllSparkIdsArgs>;
}>;

export type GetAllSparkIdsQuery = {
  __typename?: "Query";
  getAllSparkIds?: {
    __typename?: "AllSparkIdsResponse";
    userId?: string | null;
  } | null;
};

export type CreateSparkMutationVariables = Exact<{
  params?: InputMaybe<CreateSparkParams>;
}>;

export type CreateSparkMutation = {
  __typename?: "Mutation";
  createSpark?: {
    __typename?: "Spark";
    id: string;
    notes?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    shared?: boolean | null;
    clusters?: Array<{
      __typename?: "Cluster";
      id: string;
      name?: string | null;
      shared?: boolean | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null> | null;
    connections?: Array<{
      __typename?: "Connection";
      id: string;
      createdAt?: any | null;
      sparks?: Array<{
        __typename?: "Spark";
        id: string;
        notes?: string | null;
        createdAt?: any | null;
        shared?: boolean | null;
        user?: { __typename?: "User"; id: string } | null;
      } | null> | null;
    } | null> | null;
    media?: {
      __typename?: "Media";
      type: MediaType;
      url?: string | null;
    } | null;
    resource?: {
      __typename?: "Resource";
      category?: ResourceCategory | null;
      metadata?: {
        __typename?: "Metadata";
        title?: string | null;
        description?: string | null;
        url?: string | null;
        author?: string | null;
        platform?: string | null;
        chapter?: string | null;
        ogData?: {
          __typename?: "OpenGraphData";
          title?: string | null;
          type?: string | null;
          url?: string | null;
          description?: string | null;
          determiner?: string | null;
          locale?: string | null;
          localeAlternate?: string | null;
          siteName?: string | null;
        } | null;
      } | null;
      website?: {
        __typename?: "Website";
        url?: string | null;
        metadata?: {
          __typename?: "Metadata";
          title?: string | null;
          description?: string | null;
          url?: string | null;
          author?: string | null;
          platform?: string | null;
          chapter?: string | null;
          ogData?: {
            __typename?: "OpenGraphData";
            title?: string | null;
            type?: string | null;
            description?: string | null;
            url?: string | null;
            determiner?: string | null;
            locale?: string | null;
            localeAlternate?: string | null;
            siteName?: string | null;
          } | null;
        } | null;
      } | null;
      social?: {
        __typename?: "Social";
        author?: string | null;
        url?: string | null;
        platform?: SocialPlatform | null;
        metadata?: {
          __typename?: "Metadata";
          title?: string | null;
          description?: string | null;
          url?: string | null;
          author?: string | null;
          platform?: string | null;
          chapter?: string | null;
          ogData?: {
            __typename?: "OpenGraphData";
            title?: string | null;
            type?: string | null;
            url?: string | null;
            description?: string | null;
            determiner?: string | null;
            locale?: string | null;
            localeAlternate?: string | null;
            siteName?: string | null;
          } | null;
        } | null;
      } | null;
      video?: {
        __typename?: "Video";
        url?: string | null;
        metadata?: {
          __typename?: "Metadata";
          title?: string | null;
          description?: string | null;
          url?: string | null;
          author?: string | null;
          platform?: string | null;
          chapter?: string | null;
          ogData?: {
            __typename?: "OpenGraphData";
            title?: string | null;
            type?: string | null;
            url?: string | null;
            description?: string | null;
            determiner?: string | null;
            locale?: string | null;
            localeAlternate?: string | null;
            siteName?: string | null;
          } | null;
        } | null;
      } | null;
      book?: {
        __typename?: "Book";
        title?: string | null;
        author?: string | null;
        chapter?: string | null;
        metadata?: {
          __typename?: "Metadata";
          title?: string | null;
          description?: string | null;
          url?: string | null;
          author?: string | null;
          platform?: string | null;
          chapter?: string | null;
          ogData?: {
            __typename?: "OpenGraphData";
            title?: string | null;
            type?: string | null;
            url?: string | null;
            description?: string | null;
            determiner?: string | null;
            locale?: string | null;
            localeAlternate?: string | null;
            siteName?: string | null;
          } | null;
        } | null;
      } | null;
      audiobook?: {
        __typename?: "Audiobook";
        chapter?: string | null;
        title?: string | null;
        author?: string | null;
        url?: string | null;
        metadata?: {
          __typename?: "Metadata";
          title?: string | null;
          description?: string | null;
          url?: string | null;
          author?: string | null;
          platform?: string | null;
          chapter?: string | null;
          ogData?: {
            __typename?: "OpenGraphData";
            title?: string | null;
            type?: string | null;
            url?: string | null;
            description?: string | null;
            determiner?: string | null;
            locale?: string | null;
            localeAlternate?: string | null;
            siteName?: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    user?: {
      __typename?: "User";
      id: string;
      firstName?: string | null;
      name?: string | null;
    } | null;
  } | null;
};

export const ClusterFragmentDoc = gql`
  fragment Cluster on Cluster {
    id
    name
    description
    createdAt
    updatedAt
    shared
    user {
      id
      firstName
      name
    }
  }
`;
export const MetadataFragmentDoc = gql`
  fragment Metadata on Metadata {
    title
    description
    url
    author
    platform
    chapter
    ogData {
      title
      type
      url
      description
      determiner
      locale
      localeAlternate
      siteName
    }
  }
`;
export const SparkFragmentDoc = gql`
  fragment Spark on Spark {
    id
    notes
    createdAt
    updatedAt
    shared
    clusters {
      id
      name
      shared
      createdAt
      updatedAt
    }
    connections {
      id
      createdAt
      sparks {
        id
        notes
        createdAt
        shared
        user {
          id
        }
      }
    }
    media {
      type
      url
    }
    resource {
      category
      metadata {
        ...Metadata
      }
      website {
        url
        metadata {
          ...Metadata
          ogData {
            title
            type
            description
            url
          }
        }
      }
      social {
        author
        url
        platform
        metadata {
          ...Metadata
        }
      }
      video {
        url
        metadata {
          ...Metadata
        }
      }
      book {
        title
        author
        chapter
        metadata {
          ...Metadata
        }
      }
      audiobook {
        chapter
        title
        author
        url
        metadata {
          ...Metadata
        }
      }
    }
    user {
      id
      firstName
      name
    }
  }
  ${MetadataFragmentDoc}
`;
export const GetClusterDataDocument = gql`
  query GetClusterData($params: GetClusterParams) {
    getClusterData(params: $params) {
      id
      name
    }
  }
`;

/**
 * __useGetClusterDataQuery__
 *
 * To run a query within a React component, call `useGetClusterDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClusterDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClusterDataQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetClusterDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClusterDataQuery,
    GetClusterDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClusterDataQuery, GetClusterDataQueryVariables>(
    GetClusterDataDocument,
    options,
  );
}
export function useGetClusterDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClusterDataQuery,
    GetClusterDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClusterDataQuery, GetClusterDataQueryVariables>(
    GetClusterDataDocument,
    options,
  );
}
export type GetClusterDataQueryHookResult = ReturnType<
  typeof useGetClusterDataQuery
>;
export type GetClusterDataLazyQueryHookResult = ReturnType<
  typeof useGetClusterDataLazyQuery
>;
export type GetClusterDataQueryResult = Apollo.QueryResult<
  GetClusterDataQuery,
  GetClusterDataQueryVariables
>;
export const GetClusterDocument = gql`
  query GetCluster($args: GetClusterArgs) {
    getCluster(args: $args) {
      ...Cluster
      sparks {
        id
      }
      user {
        id
        firstName
        lastName
        name
      }
    }
  }
  ${ClusterFragmentDoc}
`;

/**
 * __useGetClusterQuery__
 *
 * To run a query within a React component, call `useGetClusterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClusterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClusterQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useGetClusterQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClusterQuery,
    GetClusterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClusterQuery, GetClusterQueryVariables>(
    GetClusterDocument,
    options,
  );
}
export function useGetClusterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClusterQuery,
    GetClusterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClusterQuery, GetClusterQueryVariables>(
    GetClusterDocument,
    options,
  );
}
export type GetClusterQueryHookResult = ReturnType<typeof useGetClusterQuery>;
export type GetClusterLazyQueryHookResult = ReturnType<
  typeof useGetClusterLazyQuery
>;
export type GetClusterQueryResult = Apollo.QueryResult<
  GetClusterQuery,
  GetClusterQueryVariables
>;
export const GetClusterIsSharedDocument = gql`
  query GetClusterIsShared($args: GetClusterArgs) {
    getClusterIsShared(args: $args) {
      shared
    }
  }
`;

/**
 * __useGetClusterIsSharedQuery__
 *
 * To run a query within a React component, call `useGetClusterIsSharedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClusterIsSharedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClusterIsSharedQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useGetClusterIsSharedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClusterIsSharedQuery,
    GetClusterIsSharedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClusterIsSharedQuery,
    GetClusterIsSharedQueryVariables
  >(GetClusterIsSharedDocument, options);
}
export function useGetClusterIsSharedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClusterIsSharedQuery,
    GetClusterIsSharedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClusterIsSharedQuery,
    GetClusterIsSharedQueryVariables
  >(GetClusterIsSharedDocument, options);
}
export type GetClusterIsSharedQueryHookResult = ReturnType<
  typeof useGetClusterIsSharedQuery
>;
export type GetClusterIsSharedLazyQueryHookResult = ReturnType<
  typeof useGetClusterIsSharedLazyQuery
>;
export type GetClusterIsSharedQueryResult = Apollo.QueryResult<
  GetClusterIsSharedQuery,
  GetClusterIsSharedQueryVariables
>;
export const GetClustersDocument = gql`
  query GetClusters($args: GetClustersArgs) {
    getClusters(args: $args) {
      clusters {
        ...Cluster
        user {
          id
          firstName
          lastName
          name
        }
      }
    }
  }
  ${ClusterFragmentDoc}
`;

/**
 * __useGetClustersQuery__
 *
 * To run a query within a React component, call `useGetClustersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClustersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClustersQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useGetClustersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClustersQuery,
    GetClustersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClustersQuery, GetClustersQueryVariables>(
    GetClustersDocument,
    options,
  );
}
export function useGetClustersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClustersQuery,
    GetClustersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClustersQuery, GetClustersQueryVariables>(
    GetClustersDocument,
    options,
  );
}
export type GetClustersQueryHookResult = ReturnType<typeof useGetClustersQuery>;
export type GetClustersLazyQueryHookResult = ReturnType<
  typeof useGetClustersLazyQuery
>;
export type GetClustersQueryResult = Apollo.QueryResult<
  GetClustersQuery,
  GetClustersQueryVariables
>;
export const GetSparkDocument = gql`
  query GetSpark($args: GetSparkArgs) {
    getSpark(args: $args) {
      ...Spark
      clusters {
        id
        name
        shared
        createdAt
        updatedAt
        user {
          id
          firstName
          lastName
          name
        }
      }
      connections {
        sparks {
          ...Spark
        }
      }
    }
  }
  ${SparkFragmentDoc}
`;

/**
 * __useGetSparkQuery__
 *
 * To run a query within a React component, call `useGetSparkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSparkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSparkQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useGetSparkQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSparkQuery, GetSparkQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSparkQuery, GetSparkQueryVariables>(
    GetSparkDocument,
    options,
  );
}
export function useGetSparkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSparkQuery,
    GetSparkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSparkQuery, GetSparkQueryVariables>(
    GetSparkDocument,
    options,
  );
}
export type GetSparkQueryHookResult = ReturnType<typeof useGetSparkQuery>;
export type GetSparkLazyQueryHookResult = ReturnType<
  typeof useGetSparkLazyQuery
>;
export type GetSparkQueryResult = Apollo.QueryResult<
  GetSparkQuery,
  GetSparkQueryVariables
>;
export const GetSparksDocument = gql`
  query GetSparks($args: GetSparksArgs) {
    getSparks(args: $args) {
      hasMore
      sparks {
        ...Spark
        clusters {
          id
          name
          shared
          createdAt
          updatedAt
        }
        connections {
          id
          createdAt
          sparks {
            ...Spark
          }
        }
        user {
          id
          firstName
          lastName
          name
        }
      }
    }
  }
  ${SparkFragmentDoc}
`;

/**
 * __useGetSparksQuery__
 *
 * To run a query within a React component, call `useGetSparksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSparksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSparksQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useGetSparksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSparksQuery,
    GetSparksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSparksQuery, GetSparksQueryVariables>(
    GetSparksDocument,
    options,
  );
}
export function useGetSparksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSparksQuery,
    GetSparksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSparksQuery, GetSparksQueryVariables>(
    GetSparksDocument,
    options,
  );
}
export type GetSparksQueryHookResult = ReturnType<typeof useGetSparksQuery>;
export type GetSparksLazyQueryHookResult = ReturnType<
  typeof useGetSparksLazyQuery
>;
export type GetSparksQueryResult = Apollo.QueryResult<
  GetSparksQuery,
  GetSparksQueryVariables
>;
export const GetSparkIsSharedDocument = gql`
  query GetSparkIsShared($args: GetSparkArgs) {
    getSparkIsShared(args: $args) {
      shared
    }
  }
`;

/**
 * __useGetSparkIsSharedQuery__
 *
 * To run a query within a React component, call `useGetSparkIsSharedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSparkIsSharedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSparkIsSharedQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useGetSparkIsSharedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSparkIsSharedQuery,
    GetSparkIsSharedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSparkIsSharedQuery, GetSparkIsSharedQueryVariables>(
    GetSparkIsSharedDocument,
    options,
  );
}
export function useGetSparkIsSharedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSparkIsSharedQuery,
    GetSparkIsSharedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSparkIsSharedQuery,
    GetSparkIsSharedQueryVariables
  >(GetSparkIsSharedDocument, options);
}
export type GetSparkIsSharedQueryHookResult = ReturnType<
  typeof useGetSparkIsSharedQuery
>;
export type GetSparkIsSharedLazyQueryHookResult = ReturnType<
  typeof useGetSparkIsSharedLazyQuery
>;
export type GetSparkIsSharedQueryResult = Apollo.QueryResult<
  GetSparkIsSharedQuery,
  GetSparkIsSharedQueryVariables
>;
export const GetSparksSharedWithUserDocument = gql`
  query GetSparksSharedWithUser($params: GetSparksSharedWithUserParams) {
    getSparksSharedWithUser(params: $params) {
      ...Spark
    }
  }
  ${SparkFragmentDoc}
`;

/**
 * __useGetSparksSharedWithUserQuery__
 *
 * To run a query within a React component, call `useGetSparksSharedWithUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSparksSharedWithUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSparksSharedWithUserQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetSparksSharedWithUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSparksSharedWithUserQuery,
    GetSparksSharedWithUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSparksSharedWithUserQuery,
    GetSparksSharedWithUserQueryVariables
  >(GetSparksSharedWithUserDocument, options);
}
export function useGetSparksSharedWithUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSparksSharedWithUserQuery,
    GetSparksSharedWithUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSparksSharedWithUserQuery,
    GetSparksSharedWithUserQueryVariables
  >(GetSparksSharedWithUserDocument, options);
}
export type GetSparksSharedWithUserQueryHookResult = ReturnType<
  typeof useGetSparksSharedWithUserQuery
>;
export type GetSparksSharedWithUserLazyQueryHookResult = ReturnType<
  typeof useGetSparksSharedWithUserLazyQuery
>;
export type GetSparksSharedWithUserQueryResult = Apollo.QueryResult<
  GetSparksSharedWithUserQuery,
  GetSparksSharedWithUserQueryVariables
>;
export const GetRelatedSparksDocument = gql`
  query GetRelatedSparks($args: GetRelatedSparksArgs) {
    getRelatedSparks(args: $args) {
      sparks {
        ...Spark
      }
    }
  }
  ${SparkFragmentDoc}
`;

/**
 * __useGetRelatedSparksQuery__
 *
 * To run a query within a React component, call `useGetRelatedSparksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRelatedSparksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRelatedSparksQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useGetRelatedSparksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRelatedSparksQuery,
    GetRelatedSparksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRelatedSparksQuery, GetRelatedSparksQueryVariables>(
    GetRelatedSparksDocument,
    options,
  );
}
export function useGetRelatedSparksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRelatedSparksQuery,
    GetRelatedSparksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRelatedSparksQuery,
    GetRelatedSparksQueryVariables
  >(GetRelatedSparksDocument, options);
}
export type GetRelatedSparksQueryHookResult = ReturnType<
  typeof useGetRelatedSparksQuery
>;
export type GetRelatedSparksLazyQueryHookResult = ReturnType<
  typeof useGetRelatedSparksLazyQuery
>;
export type GetRelatedSparksQueryResult = Apollo.QueryResult<
  GetRelatedSparksQuery,
  GetRelatedSparksQueryVariables
>;
export const GetSparksToConnectToSparkDocument = gql`
  query GetSparksToConnectToSpark($args: GetSparksToConnectToSparkArgs) {
    getSparksToConnectToSpark(args: $args) {
      sparks {
        ...Spark
      }
    }
  }
  ${SparkFragmentDoc}
`;

/**
 * __useGetSparksToConnectToSparkQuery__
 *
 * To run a query within a React component, call `useGetSparksToConnectToSparkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSparksToConnectToSparkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSparksToConnectToSparkQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useGetSparksToConnectToSparkQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSparksToConnectToSparkQuery,
    GetSparksToConnectToSparkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSparksToConnectToSparkQuery,
    GetSparksToConnectToSparkQueryVariables
  >(GetSparksToConnectToSparkDocument, options);
}
export function useGetSparksToConnectToSparkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSparksToConnectToSparkQuery,
    GetSparksToConnectToSparkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSparksToConnectToSparkQuery,
    GetSparksToConnectToSparkQueryVariables
  >(GetSparksToConnectToSparkDocument, options);
}
export type GetSparksToConnectToSparkQueryHookResult = ReturnType<
  typeof useGetSparksToConnectToSparkQuery
>;
export type GetSparksToConnectToSparkLazyQueryHookResult = ReturnType<
  typeof useGetSparksToConnectToSparkLazyQuery
>;
export type GetSparksToConnectToSparkQueryResult = Apollo.QueryResult<
  GetSparksToConnectToSparkQuery,
  GetSparksToConnectToSparkQueryVariables
>;
export const GetAllSparkIdsDocument = gql`
  query GetAllSparkIds($args: GetAllSparkIdsArgs) {
    getAllSparkIds(args: $args) {
      userId
    }
  }
`;

/**
 * __useGetAllSparkIdsQuery__
 *
 * To run a query within a React component, call `useGetAllSparkIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSparkIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSparkIdsQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useGetAllSparkIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllSparkIdsQuery,
    GetAllSparkIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllSparkIdsQuery, GetAllSparkIdsQueryVariables>(
    GetAllSparkIdsDocument,
    options,
  );
}
export function useGetAllSparkIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllSparkIdsQuery,
    GetAllSparkIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllSparkIdsQuery, GetAllSparkIdsQueryVariables>(
    GetAllSparkIdsDocument,
    options,
  );
}
export type GetAllSparkIdsQueryHookResult = ReturnType<
  typeof useGetAllSparkIdsQuery
>;
export type GetAllSparkIdsLazyQueryHookResult = ReturnType<
  typeof useGetAllSparkIdsLazyQuery
>;
export type GetAllSparkIdsQueryResult = Apollo.QueryResult<
  GetAllSparkIdsQuery,
  GetAllSparkIdsQueryVariables
>;
export const CreateSparkDocument = gql`
  mutation CreateSpark($params: CreateSparkParams) {
    createSpark(params: $params) {
      ...Spark
    }
  }
  ${SparkFragmentDoc}
`;
export type CreateSparkMutationFn = Apollo.MutationFunction<
  CreateSparkMutation,
  CreateSparkMutationVariables
>;

/**
 * __useCreateSparkMutation__
 *
 * To run a mutation, you first call `useCreateSparkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSparkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSparkMutation, { data, loading, error }] = useCreateSparkMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateSparkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSparkMutation,
    CreateSparkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSparkMutation, CreateSparkMutationVariables>(
    CreateSparkDocument,
    options,
  );
}
export type CreateSparkMutationHookResult = ReturnType<
  typeof useCreateSparkMutation
>;
export type CreateSparkMutationResult =
  Apollo.MutationResult<CreateSparkMutation>;
export type CreateSparkMutationOptions = Apollo.BaseMutationOptions<
  CreateSparkMutation,
  CreateSparkMutationVariables
>;
